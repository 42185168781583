<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>

            <b-col md="6">
              <b-form-group
                  label="Árazás típus"
              >
                <validation-provider
                    #default="{ errors }"
                    name="árazás típus"
                    rules="required"
                    vid="pricingType"
                >
                  <b-form-input
                      :value="formData.pricingTypeName"
                      readonly
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Kezdés napja"
                  description="HK: munkanap, HV: nem munkanap"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kezdés napja"
                    rules="required"
                    vid="startDayType"
                >
                  <b-form-input
                      :value="formData.startDayType"
                      readonly
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Min. napok száma"
              >
                <validation-provider
                    #default="{ errors }"
                    name="min. napok száma"
                    rules="required"
                    vid="minNumberOfDays"
                >
                  <b-form-input
                      :value="formData.minNumberOfDays"
                      readonly
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Max. napok száma"
              >
                <validation-provider
                    #default="{ errors }"
                    name="max. napok száma"
                    vid="maxNumberOfDays"
                >
                  <b-form-input
                      :value="formData.maxNumberOfDays"
                      readonly
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Min. résztvevők száma"
              >
                <validation-provider
                    #default="{ errors }"
                    name="min. résztvevők száma"
                    rules="required"
                    vid="minNumberOfParticipants"
                >
                  <b-form-input
                      :value="formData.minNumberOfParticipants"
                      readonly
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label="Max. résztvevők száma"
              >
                <validation-provider
                    #default="{ errors }"
                    name="max. résztvevők száma"
                    vid="maxNumberOfParticipants"
                >
                  <b-form-input
                      :value="formData.maxNumberOfParticipants"
                      readonly
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Érvényesség kezdete"
              >
                <validation-provider
                    #default="{ errors }"
                    name="érvényesség kezdete"
                    rules="required"
                    vid="validFrom"
                >
                  <flat-pickr
                      v-model="formData.validFrom"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Bruttó ár"
              >
                <validation-provider
                    #default="{ errors }"
                    name="bruttó ár"
                    rules="required"
                    vid="price"
                >
                  <b-form-input
                      v-model="formData.price"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BLink, BOverlay,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import tabs from '@/views/pages/_components/_tabs/_reservationPricingBaseVariableFee/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BLink,
    BOverlay,
    tabs,
    cancelAndGOTOPreviousPage,
    flatPickr,
  },
  data() {
    return {
      role: 'reservation',
      tabsLoading: false,
      formData: {
        id: '',
        pricingTypeName: '',
        startDayType: '',
        minNumberOfDays: '',
        maxNumberOfDays: '',
        minNumberOfParticipants: '',
        maxNumberOfParticipants: '',
        validFrom: '',
        price: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      required,
    }
  },
  created() {
    this.initEntity()
  },
  methods: {
    initEntity(onlyTabs = false) {
      this.$store.dispatch('fetchReservationPricingBaseVariableFee', this.$route.params.id).then(response => {
        this.formData.validFrom = response.data.now
        this.processEntity(response.data.entity, onlyTabs)
      })
    },
    processEntity(entity, onlyTabs = false) {
      this.tabsLoading = true;

      if (!onlyTabs) {
        this.formData.id = this.$route.params.id
        this.formData.pricingTypeName = entity.pricingTypeName
        this.formData.startDayType = entity.start_day_type
        this.formData.minNumberOfDays = entity.min_number_of_days
        this.formData.maxNumberOfDays = entity.max_number_of_days
        this.formData.minNumberOfParticipants = entity.min_number_of_participants
        this.formData.maxNumberOfParticipants = entity.max_number_of_participants
        this.formData.price = parseInt(entity.gross_price)
      }

      this.tabsLoading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshLog()
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateReservationPricingBaseVariableFee', fd).then(async response => {
            this.processEntity(response.data.entity)
            this.$helpers.showSuccessToast()
            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'reservation-pricing-base-variable-fees'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
